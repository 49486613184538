import React, { useCallback, useContext, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import RelativeLink from "app/pages/.shared/RelativeLink";
import { BackButton } from "app/pages/.shared/BackButton/BackButton";
import LogoBrandContainer from "app/pages/.shared/LogoBrand/LogoBrandContainer";
import { useHistory, useLocation } from "react-router-dom";
import AppGlobalsContext from "app/AppGlobalsContext";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import { AsyncSmartDPSearchFormContainer } from "app/pages/SmartDP/SmartDPSearchSummary/SmartDPSearchSummary";
import { buildSDPSearchPayload } from "app/utils/smartDPUtils";
import PopoverHover from "app/pages/.shared/Popover/PopoverHover";
import AccountMenu from "app/pages/Header/AccountMenu/AccountMenu";
import "./SmartDPHeader.scss";
import { BRANDS } from "app/constants";
import SidePanel from "app/pages/.shared/SidePanel/SidePanel";
import { AsideMenuAccountContainer } from "app/pages/.shared/AsideMenu/AsideMenuAccountContainer";
import HamburgerAccount from "app/pages/.shared/HamburgerAccount/HamburgerAccount";
import SignupMenuAccount from "app/pages/.shared/MenuAccount/SignupMenuAccount/SignupMenuAccount";
import { FormattedMessage } from "react-intl";
import IconAwesomeUnlock from "app/pages/.shared/IconAwesomeUnlock";
import SvgIconMagnifyingGlassBold from "app/pages/.shared/IconMagnifyingGlassBold";

const SmartDPHeader = ({
	displayBackButton,
	isLogoSmall,
	operatorEmail,
	resetAllSDPProductsFilter,
	userIsConnected,
	isWhiteLabel,
	shouldDisplayCreditNotesEntry,
	enableSponsorship,
	enableCredits,
	creditsAmount,
	creditNotesAmount,
	partnercode,
	openCustomerRequestCount,
	logoutClick,
}) => {
	const [isSideMenuOpened, toggleSideMenu] = useState(false);
	const { pathname } = useLocation();
	const history = useHistory();
	const { resolution, brand } = useContext(AppGlobalsContext);
	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;
	const isBrandEH = brand === BRANDS.EH;

	const logoClass = classNames("smart-dp-header__logo", {
		"smart-dp-header__logo--small": isLogoSmall,
	});

	const headerClass = classNames("smart-dp-header", { "smart-dp-header--eh": isBrandEH });

	const handleFormSubmission = useCallback((data, path) => {
		const queryParams = buildSDPSearchPayload(data);

		resetAllSDPProductsFilter();

		if (path === "/sdp/listing") {
			history.replace({ pathname: "/sdp/listing", search: queryParams });
		} else {
			history.push({ pathname: "/sdp/listing", search: queryParams });
		}
	}, []);

	const openSideMenu = useCallback(() => {
		toggleSideMenu(!isSideMenuOpened);
	}, [isSideMenuOpened]);

	const closeSideMenu = useCallback(() => {
		toggleSideMenu(false);
	}, []);

	const showAccountMenu =
		!pathname.includes("booking/quote") &&
		!pathname.includes("booking/payment") &&
		!pathname.includes("booking/confirmation") &&
		(isWhiteLabel || userIsConnected);

	const disabledHomeRedirect =
		pathname.includes("booking/quote") || pathname.includes("booking/payment");

	return (
		<header className={headerClass}>
			<div className="smart-dp-header__container">
				{displayBackButton && (
					<div className="smart-dp-header__back">
						<BackButton forceBrowserBack={true} />
					</div>
				)}
				<div className={logoClass}>
					{disabledHomeRedirect ? (
						<LogoBrandContainer />
					) : (
						<RelativeLink to={{ pathname: "/" }} data-cy="header-back-link">
							<LogoBrandContainer />
						</RelativeLink>
					)}
				</div>
				{(pathname === "/sdp/listing" || pathname === "/sdp/booking/quote") && !isMobile && (
					<div className="smart-dp-header__form">
						<AsyncSmartDPSearchFormContainer onSuccess={handleFormSubmission} />
					</div>
				)}
				{showAccountMenu ? (
					<div className="smart-dp-header__account-menu">
						{isMobile ? (
							<div className="header__hamburger">
								<HamburgerAccount onToogle={openSideMenu} />
								<SidePanel
									isOpen={isSideMenuOpened}
									direction={"rtl"}
									onClose={closeSideMenu}
								>
									<AsideMenuAccountContainer onClose={closeSideMenu} />
								</SidePanel>
							</div>
						) : (
							<PopoverHover
								popOverButtonClassName="smart-dp-header__popover-button"
								popOverClassName="smart-dp-header__dropdown"
								tipSize={0.01}
								buttonLabel={<HamburgerAccount />}
								closeOnBodyClick={true}
								popOverPosition={"below"}
								popOverContent={
									userIsConnected ? (
										<div className="smart-dp-header__menu">
											<RelativeLink
												to={{ pathname: "/" }}
												className="smart-dp-header__search-trip"
											>
												<SvgIconMagnifyingGlassBold
													height={20}
													width={20}
													style={{ marginRight: "10px" }}
												/>
												<FormattedMessage id="menu.item.search.trip" />
											</RelativeLink>
											<RelativeLink
												to={{ pathname: "/listing" }}
												className="smart-dp-header__flashsale"
											>
												<IconAwesomeUnlock />
												<FormattedMessage id="menu.mobile.flashsales" />
											</RelativeLink>
											<AccountMenu
												brand={brand}
												enableSponsorship={enableSponsorship}
												enableCredits={enableCredits}
												onLogoutClick={logoutClick}
												partnerCode={partnercode}
												creditsAmount={creditsAmount}
												creditNotesAmount={creditNotesAmount}
												shouldDisplayCreditNotesEntry={
													shouldDisplayCreditNotesEntry
												}
												userIsConnected={userIsConnected}
												showCustomerRequestNotification={
													openCustomerRequestCount > 0
												}
											/>
										</div>
									) : (
										<SignupMenuAccount />
									)
								}
							/>
						)}
					</div>
				) : (
					<div />
				)}
				{operatorEmail && (
					<div className="smart-dp-header__operator">
						<strong>Agent :</strong> {operatorEmail}
					</div>
				)}
			</div>
		</header>
	);
};

SmartDPHeader.propTypes = {
	displayBackButton: PropTypes.bool,
	isLogoSmall: PropTypes.bool,
	operatorEmail: PropTypes.string,
	isWhiteLabel: PropTypes.bool,
	userIsConnected: PropTypes.bool,
	resetAllSDPProductsFilter: PropTypes.func,
	shouldDisplayCreditNotesEntry: PropTypes.bool,
	enableSponsorship: PropTypes.bool,
	enableCredits: PropTypes.bool,
	openCustomerRequestCount: PropTypes.number,
	creditNotesAmount: PropTypes.number,
	partnercode: PropTypes.string,
	logoutClick: PropTypes.func,
	creditsAmount: PropTypes.number,
};

SmartDPHeader.defaultProps = {
	resetAllSDPProductsFilter: () => {},
};

export default React.memo(SmartDPHeader);
