import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import RelativeLink from "app/pages/.shared/RelativeLink";
import NewBadge from "app/pages/Header/conditionnalComponents/NewBadge";

import "./AccountMenu.scss";
import AmountContainer from "app/pages/.shared/AmountContainer";
import { brandPropTypes } from "app/utils/propTypes";
import { BRANDS } from "app/constants";
import DotNotification from "app/pages/.shared/DotNotification/DotNotification";
import IconLogout from "app/pages/.shared/static/icons/IconLogout";

const AccountMenu = ({
	enableSponsorship,
	enableCredits,
	onLogoutClick,
	partnerCode,
	creditsAmount,
	creditNotesAmount,
	brand,
	shouldDisplayCreditNotesEntry,
	userIsConnected,
	showCustomerRequestNotification,
}) => {
	const isBrandXX = brand === BRANDS.XX;

	return (
		<nav className="account-menu">
			<ul className="account-menu__list">
				{!isBrandXX && (
					<li className="account-menu__item">
						<RelativeLink to={{ pathname: "/account/profile" }}>
							<FormattedMessage id="menu.item.my.profile" />
						</RelativeLink>
					</li>
				)}

				{enableCredits && (
					<li className="account-menu__item">
						<RelativeLink to={{ pathname: "/account/mycredits" }}>
							<FormattedMessage
								id={
									creditsAmount > 0
										? "menu.item.my.credits.with.amount"
										: "menu.item.my.credits"
								}
								tagName="span"
								values={{
									creditAmount: (
										<AmountContainer
											prefix="("
											suffix=")"
											amount={creditsAmount}
										/>
									),
								}}
							/>
						</RelativeLink>
					</li>
				)}

				{isBrandXX && (
					<li className="account-menu__item">
						<RelativeLink to={{ pathname: "/account/mybookings" }}>
							<FormattedMessage id="menu.item.my.bookings" />
							{showCustomerRequestNotification && <DotNotification />}
						</RelativeLink>
					</li>
				)}

				{shouldDisplayCreditNotesEntry && (
					<li className="account-menu__item">
						<RelativeLink to={{ pathname: "/account/myrefunds" }}>
							<FormattedMessage
								id={
									creditNotesAmount > 0
										? "menu.item.my.refunds.with.amount"
										: "menu.item.my.refunds"
								}
								values={{
									refundAmount: (
										<AmountContainer
											prefix="("
											suffix=")"
											amount={creditNotesAmount}
										/>
									),
								}}
								tagName="span"
							/>
						</RelativeLink>
					</li>
				)}

				{isBrandXX && creditNotesAmount > 0 && (
					<li className="account-menu__item">
						<RelativeLink to={{ pathname: "/listing" }}>
							<FormattedMessage id="menu.mobile.all.sales" />
						</RelativeLink>
					</li>
				)}

				{enableSponsorship && (
					<li className="account-menu__item">
						<RelativeLink to={{ pathname: "/account/sponsorship" }}>
							<FormattedMessage id="menu.item.sponsorship" />
							<NewBadge partnerCode={partnerCode} />
						</RelativeLink>
					</li>
				)}

				{!isBrandXX && (
					<React.Fragment>
						<li className="account-menu__item">
							<RelativeLink to={{ pathname: "/account/mybookings" }}>
								<FormattedMessage id="menu.item.my.bookings" />
								{showCustomerRequestNotification && <DotNotification />}
							</RelativeLink>
						</li>
						<li className="account-menu__item">
							<RelativeLink to={{ pathname: "/account/subscriptions" }}>
								<FormattedMessage id="menu.item.my.subscriptions" />
							</RelativeLink>
						</li>
						<li className="account-menu__item">
							<RelativeLink to={{ pathname: "/account/security" }}>
								<FormattedMessage id="menu.item.security" />
							</RelativeLink>
						</li>
						<li className="account-menu__item">
							<RelativeLink
								rel="noopener noreferrer"
								target="_blank"
								to={{ pathname: "/faq" }}
							>
								<FormattedMessage id="menu.item.help" />
							</RelativeLink>
						</li>
					</React.Fragment>
				)}

				{userIsConnected && (
					<li className="account-menu__item account-menu__item--logout">
						<a className="relative-link" onClick={onLogoutClick}>
							<IconLogout height={20} style={{ marginRight: "10px" }} />
							<FormattedMessage id="menu.item.logout" />
						</a>
					</li>
				)}
			</ul>
		</nav>
	);
};

AccountMenu.propTypes = {
	enableSponsorship: PropTypes.bool,
	shouldDisplayCreditNotesEntry: PropTypes.bool,
	enableCredits: PropTypes.bool,
	onLogoutClick: PropTypes.func,
	partnerCode: PropTypes.string,
	creditsAmount: PropTypes.number,
	creditNotesAmount: PropTypes.number,
	brand: brandPropTypes,
	userIsConnected: PropTypes.bool,
	showCustomerRequestNotification: PropTypes.bool,
};

export default AccountMenu;
